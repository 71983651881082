import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import _ from 'lodash';
import {sanitizeText, sanitizeLineBreaks} from 'utils/html';
import Image from 'Components/Image';
import ArrowLarge from 'Assets/svg/arrowLarge.svg';
import s from './CardDonation.module.scss';

const CardDonation = ({title, excerpt, url, image, hideImage, isLarge, isOdd}) => {

    const sanitizedTitle = sanitizeText(title);
    const sanitizedExcerpt = sanitizeLineBreaks(excerpt);

    const sizes = [
        '(min-width: 1440px) 428px',
        '(min-width: 768px) 33vw',
        '100vw',
    ];

    const classes = classNames(
        s['CardDonation'],
        {[s['CardDonation--ShowImage']]: !hideImage},
        {[s['CardDonation--HideImage']]: hideImage},
        {[s['CardDonation--HasImage']]: !hideImage && !_.isEmpty(image)},
        {[s['CardDonation--Large']]: isLarge},
        {[s['CardDonation--Small']]: !isLarge},
        {[s['CardDonation--Odd']]: isOdd},
        {[s['CardDonation--Even']]: !isOdd},
    );
    return (
        <article className={classes}>
            <span className={s['CardDonation__Shell']} />

            <a className={s['CardDonation__Link']} href={url}>
                <span
                    className={'sr-only'}
                    dangerouslySetInnerHTML={{__html: sanitizedTitle}}
                />
            </a>

            {!hideImage &&
                <div className={s['CardDonation__Image']}>
                    {!_.isEmpty(image) &&
                        <Image {...image} sizes={sizes} />
                    }
                </div>
            }

            <div className={s['CardDonation__Content']}>
                <h3
                    className={s['CardDonation__Title']}
                    dangerouslySetInnerHTML={{__html: sanitizedTitle}}
                />

                {isLarge && !_.isEmpty(excerpt) &&
                    <p
                        className={s['CardDonation__Text']}
                        dangerouslySetInnerHTML={{__html: sanitizedExcerpt}}
                    />
                }

                <div className={s['CardDonation__Button']}><ArrowLarge /></div>
            </div>
        </article>
    );
};

CardDonation.propTypes = {
    title: PropTypes.string.isRequired,
    excerpt: PropTypes.string,
    url: PropTypes.string.isRequired,
    image: PropTypes.object,
    hideImage: PropTypes.bool,
    isLarge: PropTypes.bool,
    isOdd: PropTypes.bool,
};

CardDonation.defaultProps = {
    title: '',
    excerpt: '',
    url: '',
    image: null,
    hideImage: false,
    isLarge: false,
    isOdd: false,
};

export default CardDonation;
